var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "btn btn--reset ml-auto w-12 h-12 text-lg text-secondary-base bg-secondary-base text-black rounded-full hover:bg-secondary-dark focus:bg-secondary-dark flex flex-wrap justify-center items-center shadow-xl ssm:mr-0 -mr-4",
      attrs: { "aria-label": _vm.label, title: _vm.label },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleMenu($event)
        }
      }
    },
    [
      _c("font-awesome-icon", {
        staticClass: "text-black",
        attrs: { icon: ["far", _vm.icon] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }