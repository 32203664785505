var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h6", { staticClass: "w-full block mb-0", attrs: { id: _vm.id } }, [
    _c(
      "button",
      {
        staticClass:
          "block btn btn--reset no-underline w-full border-b border-grey-base py-6 px-4 flex border-b text-base",
        class: _vm.htmlClasses.concat([_vm.isExpanded ? "is--active" : ""]),
        attrs: {
          "aria-controls": "accordion-panel-" + _vm.id,
          "aria-expanded": _vm.isExpanded,
          "aria-label": _vm.ariaLabel + " " + _vm.toggleLabel
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggle($event)
          }
        }
      },
      [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _c("font-awesome-icon", {
              staticClass: "text-primary-base",
              attrs: { icon: ["far", _vm.expandIcon] }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }