// import $ from 'jquery';

// import Vue from 'vue';
// import App from './App.vue';

import Vue from "vue";
import svg4everybody from "svg4everybody";
import polyfill from "./polyfills";
import initModules from "./lib/init-modules";
import modulesEnabled from "./modulesEnabled";

Vue.config.productionTip = false;

const setJsDocumentClasses = () => {
  const docEl = document.documentElement;

  docEl.classList.remove("js-loading");
	docEl.classList.add("js-loaded");
};

const init = (APP) => {
  APP.DEBUG = typeof APP.DEBUG !== "undefined" ? APP.DEBUG : !process.env.NODE_ENV === "production";
	APP.modules = initModules(APP.modules, modulesEnabled, APP.DEBUG);
};

window.addEventListener("DOMContentLoaded", () => {
  setJsDocumentClasses();
	svg4everybody();

  polyfill().then(() => {
		init(window.APP);
  });
});



// ----------------------------
// Back to top
// ----------------------------
jQuery("#back-top").hide();
jQuery(function () {
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > 250) {
      jQuery('#back-top').fadeIn();
    } else {
      jQuery('#back-top').fadeOut();
    }
  });
});
jQuery("#back-top").click(function() {
  jQuery("html, body").animate({
  scrollTop: 0
  }, 750);
});


// --------------------------------------------------------------------------------------------------
// Ninja Forms event tracking | https://www.chrisains.com/seo/tracking-ninja-form-submissions-with-google-analytics-jquery/
// --------------------------------------------------------------------------------------------------
jQuery( document ).on( 'nfFormReady', function() {
  nfRadio.channel('forms').on('submit:response', function(form) {
  gtag('event', 'conversion', {'event_category': form.data.settings.title,'event_action': 'Send Form','event_label': 'Successful '+form.data.settings.title+' Enquiry'});
  console.log(form.data.settings.title + ' successfully submitted');
  });
});
