<template>
  <button
    :aria-label="label"
    class="btn btn--reset ml-auto w-12 h-12 text-lg text-secondary-base bg-secondary-base text-black rounded-full hover:bg-secondary-dark focus:bg-secondary-dark flex flex-wrap justify-center items-center shadow-xl ssm:mr-0 -mr-4"
		:title="label"
    @click.prevent="toggleMenu"
  >
    <font-awesome-icon :icon="['far', icon]" class="text-black" />
  </button>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapMobileMenuState, mapActions: mapMobileMenuActions } = createNamespacedHelpers("mobileMenu");

export default {
  computed: {
    ...mapMobileMenuState(["menuExpanded"]),
    label() {
      return this.menuExpanded ? "Hide Menu" : "Show Menu";
		},
		icon() {
			return this.menuExpanded ? "times" : "bars";
		}
  },
  methods: {
    ...mapMobileMenuActions(["toggleMenu"]),
  },
};
</script>
