import Vue from 'vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp, faAngleRight, faAngleLeft, faBars, faTimes, faCheck, faNewspaper, faEnvelope, faBuilding, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { faPhoneAlt, faStar, faLeaf, faFutbol, faPaw, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faFacebookSquare, faTwitterSquare, faLinkedin, faInstagram, faYoutube, faPinterest } from '@fortawesome/free-brands-svg-icons';

library.add(faAngleDown, faAngleRight, faAngleLeft, faAngleUp, faBars, faTimes, faCheck, faPhoneAlt, faStar, faFacebookSquare, faTwitterSquare, faLinkedin, faInstagram, faYoutube, faPinterest, faNewspaper, faEnvelope, faBuilding, faLeaf, faFutbol, faPaw, faGraduationCap, faArrowUp);

Vue.component('font-awesome-icon', FontAwesomeIcon);

dom.i2svg();
dom.watch();
